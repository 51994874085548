import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import React from 'react'
import MetaImage from '../assets/images/meta/git-analytics-new.png'
import Layout from '../components/Layout'
import { DarkLogoBlock } from '../components/blocks/DarkLogoBlock'
import HeroBlock from '../components/blocks/HeroBlock'
import LeadBlock from '../components/blocks/LeadBlock'
import QuoteMosaicBlock from '../components/blocks/QuoteMosaicBlock'
import Box from '../components/primitives/Box'
import Row from '../components/primitives/Row'
import Stack from '../components/primitives/Stack'
import Blurb from '../components/system/Blurb'
import CTABlock from '../components/blocks/CTABlock'
import G2Testimonials from '../components/G2Testimonials'
import ColumnsBlock from '../components/blocks/ColumnsBlock'
import TrendsIcon from '../assets/images/product/blurb-icons/trends.png'
import ReviewRateIcon from '../assets/images/product/blurb-icons/all-work.png'
import ToolsBlock from '../components/blocks/ToolsBlock'
import VideoBlock from '../components/blocks/VideoBlock'
import PRIcon from '../assets/images/product/blurb-icons/fast.svg'
import InvestmentDistributionIcon from '../assets/images/product/blurb-icons/slide-and-dice.png'
import BugIcon from '../assets/images/product/blurb-icons/zoom.png'
import SizeIcon from '../assets/images/product/blurb-icons/gear.png'
import CIInsightsIcon from '../assets/images/icons/ci-insights.svg'
import ClockIcon from '../assets/images/product/blurb-icons/lock.png'
import WIPicon from '../assets/images/icons/working-agreements.svg'
import QuoteBlock, { quotes } from '../components/blocks/QuoteBlock'

import { responsiveScale } from '../styles/helpers'

export const query = graphql`
  {
    backgroundImage: file(
      relativePath: { eq: "images/backgrounds/git-analytics.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, quality: 99)
      }
    }
    mosaicRight: file(
      relativePath: { eq: "images/mosaic/github-alternative.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft: file(relativePath: { eq: "images/mosaic/metrics-380.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicLeft2: file(
      relativePath: { eq: "images/mosaic/navy-notifications.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight2: file(relativePath: { eq: "images/mosaic/balance.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight3: file(relativePath: { eq: "images/mosaic/slack-purple.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    mosaicRight4: file(
      relativePath: { eq: "images/mosaic/survey-narrow.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
    scale: file(
      relativePath: { eq: "images/home/every-team-is-different.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 612)
      }
    }
    integrations: file(
      relativePath: { eq: "images/enterprise/integrations.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 670)
      }
    }
    workingAgreement1: file(
      relativePath: { eq: "images/product/overview/target.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement2: file(
      relativePath: { eq: "images/product/overview/slack-notification.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
    workingAgreement3: file(
      relativePath: { eq: "images/product/overview/surveys-experiment.png" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 438)
      }
    }
  }
`
const Gitanalyticspage = () => {
  const data = useStaticQuery(query)
  return (
    <Layout
      title="A better alternative to Git analytics tools"
      variant="dark"
      isNew
      description="Improve software development productivity by measuring a balanced set of engineering metrics."
      metaImage={MetaImage}
    >
      <HeroBlock
        title={<>A better alternative to Git&nbsp;analytics&nbsp;tools</>}
        content="Improve software development productivity by measuring a&nbsp;balanced set of engineering metrics."
        primaryButton={{ text: 'Get a demo', href: '/demo/' }}
        secondaryButton={{
          text: 'Start free trial',
          href: 'https://app.swarmia.com/signup/',
        }}
        backgroundImage={getImage(data.backgroundImage)!}
      />
      <DarkLogoBlock />
      <LeadBlock
        heading="Get more than Git analytics"
        content="Swarmia gives engineering leaders, managers, and teams access to
                valuable engineering metrics — including DORA and SPACE."
      />
      <QuoteMosaicBlock
        title="Reliable data, out of the box"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Swarmia allows you to focus on driving change with real-time,
                granular data that can be easily shared across the entire
                engineering team.
                <br />
                <br />
                Instead of building and maintaining internal dashboards to track
                DORA and other key developer metrics, modern organizations
                choose Swarmia to measure engineering productivity.
                <br />
                <br />
              </Box>
            </Stack>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight)!}
        quote={quotes.walter}
      />
      <QuoteMosaicBlock
        title="No harmful metrics"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                Just because something is easy to measure doesn’t mean it should
                be tracked. We’ve intentionally left out traditional Git
                analytics metrics like the following:
                <br />
                <br />
              </Box>
            </Stack>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Lines of code (LOC)
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Code churn and rework
              </Box>
            </Row>
            <Row space={12}>
              <Box font="large" color="dataPink">
                *
              </Box>
              <Box font="small" color="black">
                Developer stack ranking
              </Box>
            </Row>
            <Stack space={16}>
              <Box>
                <br />
                These metrics don’t help teams improve—they can lead to
                developers gaming the system and misguided managerial decisions.
                Swarmia focuses on the right measures to boost productivity
                without falling into the trap of superficial analytics.
                <br />
                <br />
              </Box>
            </Stack>
          </Stack>
        }
        imageAlign="left"
        image={getImage(data.mosaicLeft)!}
        quote={quotes.martin2}
      />
      <QuoteMosaicBlock
        title="Focus on team-level productivity"
        content={
          <Stack>
            <Stack space={16}>
              <Box>
                We know that the best teams focus on optimizing for the whole,
                not the individual.
                <br />
                <br />
                Swarmia turns GitHub data into team-centric metrics and insights
                that help your engineering organization from developers to
                managers and from directors to VPs to make better decisions.
                <br />
                <br />
                Some other tools focus on surveillance, not support. In Swarmia,
                you won’t see leaderboards ranking developers or teams from best
                to worst.
                <br />
                <br />
              </Box>
            </Stack>
          </Stack>
        }
        imageAlign="right"
        image={getImage(data.mosaicRight2)!}
        quote={quotes.alex4}
      />
      <LeadBlock
        heading="Get a complete picture of engineering productivity"
        content="Swarmia simplifies tracking and acting based on the engineering metrics that matter to your software organization."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(32)}
        columns={[
          <Blurb
            key="1"
            title="PR cycle time"
            text="Understand how long it takes for your team to complete pull requests so you can reduce waiting times and merge faster."
            icon={PRIcon}
          />,
          <Blurb
            key="2"
            title="Deployment frequency"
            text="Keep track of how often you’re deploying — and how often the deployments are failing."
            icon={TrendsIcon}
          />,
          <Blurb
            key="3"
            title="Investment distribution"
            text="Use data to start conversations that drive the teams to improve."
            icon={InvestmentDistributionIcon}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(32)}
        columns={[
          <Blurb
            key="1"
            title="Bug insights"
            text="Stay on top of the bugs your team is working on and the time it takes to fix them."
            icon={BugIcon}
          />,
          <Blurb
            key="2"
            title="Batch size"
            text="Keep your batch size small to roll out features to customers faster and with less risk."
            icon={SizeIcon}
          />,
          <Blurb
            key="3"
            title="CI insights"
            text="Troubleshoot pipeline issues to reduce CI waiting times and costs."
            icon={CIInsightsIcon}
          />,
        ]}
      />
      <ColumnsBlock
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Review time"
            text="Identify review bottlenecks and get your work merged faster."
            icon={ClockIcon}
          />,
          <Blurb
            key="2"
            title="Review rate"
            text="Maintain high code quality by maximizing the percentage of code that gets reviewed before it’s merged."
            icon={ReviewRateIcon}
          />,
          <Blurb
            key="3"
            title="WIP limits"
            text="Allow teams to set their own work-in-progress limits to boost focus and productivity."
            icon={WIPicon}
          />,
        ]}
      />
      <CTABlock title={<>Get started with Swarmia today.</>} />
      <LeadBlock
        heading="Tracking the right metrics is&nbsp;just&nbsp;the&nbsp;start"
        content="Give your engineering organization the tools to identify&nbsp;and&nbsp;remove&nbsp;bottlenecks in software development."
      />
      <ColumnsBlock
        paddingTop={responsiveScale(48)}
        paddingBottom={responsiveScale(64)}
        columns={[
          <Blurb
            key="1"
            title="Working agreements"
            text="Provide your team with the feedback loops they need to get a little bit better every day."
            image={getImage(data.workingAgreement1)}
          />,
          <Blurb
            key="2"
            title="Two-way Slack notifications"
            text="Read, reply, and react to GitHub comments without leaving Slack."
            image={getImage(data.workingAgreement2)}
          />,
          <Blurb
            key="3"
            title="Engineering surveys"
            text="Complement quantitative system metrics with sentiment data from your teams."
            image={getImage(data.workingAgreement3)}
          />,
        ]}
      />
      <QuoteBlock person="tim" />
      <ToolsBlock />
      <CTABlock
        title={
          <>
            Get the analytics you need to improve developer productivity today.
          </>
        }
      />
      <LeadBlock
        heading="Here’s how it works"
        content="Watch our 12-minute product overview to understand how you can drive engineering effectiveness with Swarmia."
      />
      <VideoBlock
        videoId="ZHcQO20bL4w"
        paddingTop={responsiveScale(64)}
        paddingBottom={responsiveScale(32)}
        thumbnail={getImage(data.videoThumbnail)!}
      />
      <Box
        // This is needed for the FAQ styles to work
        className="main-body-old-styles"
      />
      <G2Testimonials />
      <CTABlock
        title={<>Join the effective software organizations using Swarmia.</>}
      />
    </Layout>
  )
}

export default Gitanalyticspage
